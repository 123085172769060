#createTournamentBtn {
    font-family: Kanit;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.02em;
    color:rgb(255, 197, 31);
    border-radius: 4px;
    border: 1px solid rgb(255, 197, 31);
    width: 100%;
    height: 60px;
}
